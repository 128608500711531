var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: {
        center: "",
        id: _vm.inputVal,
        show: _vm.permission_overlay_status,
      },
    },
    [
      _c(
        "b-list-group-item",
        {
          staticClass: "d-flex justify-content-between align-items-center",
          attrs: { variant: "warning" },
        },
        [
          _c("span", { staticClass: "action" }, [
            _vm._v(_vm._s(_vm.permissionsDictionary(_vm.inputVal.name))),
          ]),
          _vm._v(" "),
          _c(
            "b-form-checkbox",
            {
              on: { change: _vm.onChangePermission },
              model: {
                value: _vm.inputVal.check,
                callback: function ($$v) {
                  _vm.$set(_vm.inputVal, "check", $$v)
                },
                expression: "inputVal.check",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { id: `tooltip-${_vm.inputVal.id}` },
                },
                [_c("b-icon", { attrs: { icon: "info-circle" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  attrs: {
                    placement: "top",
                    target: `tooltip-${_vm.inputVal.id}`,
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.showPermissionInfo(_vm.inputVal.name.split(".")[2])
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }