var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: {
        center: "",
        id: _vm.inputVal.alias,
        show: _vm.module_overlay_status,
      },
    },
    [
      _c(
        "b-card-header",
        {
          staticClass: "d-flex justify-content-between align-items-center",
          attrs: { "header-tag": "header", role: "tab" },
        },
        [
          _c(
            "label",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle",
                  value: `accordion_module_${_vm.inputVal.alias}`,
                  expression: "`accordion_module_${inputVal.alias}`",
                },
              ],
              attrs: { variant: "info" },
            },
            [_vm._v(_vm._s(_vm.inputVal.name))]
          ),
          _vm._v(" "),
          _c("b-form-checkbox", {
            attrs: { indeterminate: _vm.inputVal.indeterminate_state },
            on: { change: _vm.onChangeModule },
            model: {
              value: _vm.inputVal.check,
              callback: function ($$v) {
                _vm.$set(_vm.inputVal, "check", $$v)
              },
              expression: "inputVal.check",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { id: `accordion_module_${_vm.inputVal.alias}` } },
        [
          _c(
            "b-card-body",
            [
              _c("resources", {
                on: { notifyModule: _vm.onNotifyModule },
                model: {
                  value: _vm.inputVal.resources,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputVal, "resources", $$v)
                  },
                  expression: "inputVal.resources",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }