var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "resources" },
    _vm._l(_vm.inputVal, function (permissions, resource) {
      return _c(
        "b-card",
        { key: resource, staticClass: "mb-1", attrs: { "no-body": "" } },
        [
          _c("resource", {
            on: { notifyResources: _vm.onNotifyResources },
            model: {
              value: _vm.inputVal[resource],
              callback: function ($$v) {
                _vm.$set(_vm.inputVal, resource, $$v)
              },
              expression: "inputVal[resource]",
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }