<template>
  <div class="mt-3">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <!-- level-0 -->
        <accordion v-model="user_permissions.modules"></accordion>
        <!-- level-0 -->
        <div class="py-2 mb-4">
          <b-button
            @click="$router.back()"
            variant="lisaweb"
            size="sm"
            class="float-right"
            ><b-icon-chevron-double-left
              font-scale="0.9"
            ></b-icon-chevron-double-left
            >Torna indietro</b-button
          >
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import Accordion from "@/components/form/permissions-accordion/Modules.vue";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import UserPermissionsMixin from "@/mixins/UserPermissionsMixin";

export default {
  mixins: [UserPermissionsMixin],
  components: { Accordion, BaseIcon },
  name: "Permissions",
  props: {
    msg: String,
  },
  data() {
    return {
      isLoading: false,
      user: this.$route.params.user,
      repository: "user",
      user_permissions: [],
    };
  },
  mounted() {
    this.isLoading = true;
    //Fetch user permissions
    const Repo = RepositoryFactory.get(this.repository);
    Repo.show_permissions(this.user.id)
      .then((response) => {
        response.data.data.Portafoglio.forEach((el, index) => {
          if (el.name.indexOf("v2.registries") >= 0) {
            response.data.data.Portafoglio.splice(index, 1);
          }
        });

        const data = response.data.data;

        this.user_permissions = this.groupByModule(data);
        const allModules = Array.from(this.user_permissions.modules);
        this.user_permissions.modules.splice(0, allModules.length);
        this.user_permissions.modules = allModules.filter((object) => {
          return (
            object.name !== "Servizio LISA4APP" &&
            object.name !== "Priv-Easy" &&
            object.name !== "Profilo"
          );
        });
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
        this.isLoading = false;
      });
  },
};
</script>
