var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: {
        center: "",
        id: _vm.inputVal.name,
        show: _vm.resource_overlay_status,
      },
    },
    [
      _c(
        "b-card-header",
        {
          staticClass: "d-flex justify-content-between align-items-center",
          attrs: { "header-tag": "header", role: "tab" },
        },
        [
          _c(
            "label",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle",
                  value: `accordion_resource_${_vm.inputVal.name}`,
                  expression: "`accordion_resource_${inputVal.name}`",
                },
              ],
              attrs: { variant: "info" },
            },
            [_vm._v(_vm._s(_vm.resourcesDictionary(_vm.inputVal.name)))]
          ),
          _vm._v(" "),
          _c("b-form-checkbox", {
            attrs: { indeterminate: _vm.inputVal.indeterminate_state },
            on: { change: _vm.onChangeResource },
            model: {
              value: _vm.inputVal.check,
              callback: function ($$v) {
                _vm.$set(_vm.inputVal, "check", $$v)
              },
              expression: "inputVal.check",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { id: `accordion_resource_${_vm.inputVal.name}` } },
        [
          _c("permissions", {
            on: { notifyResource: _vm.onNotifyResource },
            model: {
              value: _vm.inputVal,
              callback: function ($$v) {
                _vm.inputVal = $$v
              },
              expression: "inputVal",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }