var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.inputVal
    ? _c(
        "div",
        { staticClass: "modules" },
        _vm._l(_vm.inputVal.length, function (item, index) {
          return _c(
            "b-card",
            { key: index, staticClass: "mb-2", attrs: { "no-body": "" } },
            [
              _c("module", {
                ref: "module",
                refInFor: true,
                model: {
                  value: _vm.inputVal[index],
                  callback: function ($$v) {
                    _vm.$set(_vm.inputVal, index, $$v)
                  },
                  expression: "inputVal[index]",
                },
              }),
            ],
            1
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }