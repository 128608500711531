var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card-body",
    _vm._l(_vm.inputVal.permissions.length, function (item, index) {
      return _c(
        "b-list-group",
        { key: index },
        [
          _c("permission", {
            ref: "permission",
            refInFor: true,
            on: { notifyPermissions: _vm.onNotifyPermissions },
            model: {
              value: _vm.inputVal.permissions[index],
              callback: function ($$v) {
                _vm.$set(_vm.inputVal.permissions, index, $$v)
              },
              expression: "inputVal.permissions[index]",
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }