var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-3" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _c("accordion", {
                    model: {
                      value: _vm.user_permissions.modules,
                      callback: function ($$v) {
                        _vm.$set(_vm.user_permissions, "modules", $$v)
                      },
                      expression: "user_permissions.modules",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "py-2 mb-4" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "lisaweb", size: "sm" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.back()
                            },
                          },
                        },
                        [
                          _c("b-icon-chevron-double-left", {
                            attrs: { "font-scale": "0.9" },
                          }),
                          _vm._v("Torna indietro"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }